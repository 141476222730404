.nav {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: fit-content;

    &__items {
        display: flex;
        grid-gap: 15px;
    }

    &__item {
        & svg {
            background-color: rgba(255, 255, 255, 0.1);
            cursor: pointer;
            
            padding: 10px;
            border-radius: 10px;

            width: 24px;
            height: 24px;
            fill: #999;

            transition: 0.3s ease-in-out;

            &:hover {
                fill: #fff
            }
        }
    }
}