.portfolio {
    margin-top: 50px;

    &__projects {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
    }

    &__project {
        background-color: rgb(50, 50, 50, 0.7);
        cursor: pointer;
        
        padding: 25px;
        border-radius: 10px;

        height: 175px;
        overflow: hidden;

        position: relative;

        &__title {
            font-size: 30px;
            font-weight: 600;
            color: #e8e8e8;
            letter-spacing: 3px;
            z-index: 2;
            position: relative;
        }

        &__subtitle {
            margin-top: 15px;
            color: #888888;
            font-size: 20px;
            width: 50%;
            z-index: 2;
            position: relative;
        }

        &:hover &__image {
            opacity: 1;
            rotate: -5deg;
            right: -20%;
            bottom: -35%;
        }

        &__image {
            position: absolute;
            z-index: 1;
            border-radius: 25px;
            height: 250px;
            width: auto;
            right: -30%;
            bottom: -50%;
            rotate: -15deg;

            opacity: 0.6;
            transition: 0.3s ease-in-out;
        }

        &__bottom {
            position: absolute;
            z-index: 10;
            bottom: 25px;

            display: flex;
            align-items: center;
        }

        &__github {

            & svg {
                background-color: rgba(255, 255, 255, 0.1);
                cursor: pointer;
                
                padding: 10px;
                border-radius: 10px;
    
                width: 24px;
                height: 24px;
                fill: #999;
    
                transition: 0.3s ease-in-out;
    
                &:hover {
                    fill: #fff
                }
            }
        }

        &__badges {
            margin-left: 25px;
            display: flex;
        }

        &__badge {
            & svg {
                margin-right: -15px;
                background-color: rgba(64, 64, 64, 1);
                cursor: pointer;
                
                padding: 10px;
                border-radius: 50%;
    
                width: 24px;
                height: 24px;
                fill: #999;
    
                transition: 0.3s ease-in-out;
    
                &:hover {
                    fill: #fff
                }
            }
        }

        &__badge, .react svg {
            fill: #53C1DE !important;
        }

        &__badge, .java svg {
            fill: #f77e14 !important;
        }

        &__badge, .typescript svg {
            fill: #438ce6 !important;
        }

        &__badge, .spring svg {
            fill: #64c916 !important;
        }

        &__badge, .bukkit svg {
            fill: #03690c !important;
        }
    }

}