body {
    margin: 0;
    background-color: #1B1B1B;
    height: 100%;
    font-family: "Inter", system-ui;
}

.App {
    height: 100vh;
    width: 100vw;

    position: relative;
    overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #323232;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #666;
}
