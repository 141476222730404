.roll-1 {
    position: fixed;
    z-index: -5;

    top: -300px;
    left: 0px;

    animation-name: animate;
    animation-duration: 60s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    & svg {
        opacity: 0.5;
        width: 200vh;
        height: auto;
    }
}

@keyframes animate {
    0% {
        rotate: 0deg;
    }
    25% {
        left: -50px;
    }
    100% {
        rotate: 360deg;
    }
}