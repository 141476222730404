.roll-2 {
    position: fixed;
    z-index: -5;

    bottom: -325px;
    left: -250px;

    animation-name: animate;
    animation-duration: 60s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    & svg {
        opacity: 0.5;
        width: 800px;
        height: auto;
    }
}

@keyframes animate {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}